import { RouteRecordRaw } from "vue-router";

export default [
  {
    path: "/talkroom",
    name: "Talkroom",
    component: () => import("@/pages/Talkroom/Root.vue"),
  },
  {
    path: "/talkroom/:id",
    name: "TalkroomDetail",
    component: () => import("@/pages/Talkroom/Detail.vue"),
  },
  {
    path: "/talkroom/:id/update",
    name: "TalkroomUpdate",
    component: () => import("@/pages/Talkroom/Update.vue"),
  },
  {
    path: "/talkroom/search",
    name: "TalkroomSearch",
    component: () => import("@/pages/Talkroom/Search.vue"),
  },
  {
    path: "/talkroom/upload",
    name: "TalkroomUpload",
    component: () => import("@/pages/Talkroom/Upload.vue"),
    meta: { loginRequired: true },
  },
] as Array<RouteRecordRaw>;
