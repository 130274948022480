import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import utils from "./plugins/utils.plugin";
import axios from "./plugins/axios.plugin";
import lodash from "./plugins/lodash.plugin";
import flutter from "./plugins/flutter.plugin";
import auth from "./plugins/auth.plugin";

import "./assets/styles/main.css";

const app = createApp(App);

app.use(store);
app.use(axios);
app.use(utils);
app.use(router);
app.use(lodash);
app.use(flutter);
app.use(auth);

app.mount("#app");
