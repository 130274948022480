import { App } from "vue";

export default {
  install: (app: App): void => {
    if (window.flutter_inappwebview) {
      app.config.globalProperties.$flutter = window.flutter_inappwebview;
    } else {
      app.config.globalProperties.$flutter = {
        callHandler(func: string, ...args: any[]) {
          console.log(`Call ${func}(${args})`);
          return new Promise(() => {
            return;
          });
        },
      };
    }
    window.routerReplace = app.config.globalProperties.$router.replace;
    window.goBack = app.config.globalProperties.$router.back;
  },
};
