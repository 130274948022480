import { RouteLocationNormalized } from "vue-router";
import store from "../store";
export function redirectLogin(to: RouteLocationNormalized): boolean {
  if (
    to.name !== "LoginBefore" &&
    to.name !== "Login" &&
    to.matched.some((record) => record.meta.loginRequired) &&
    !store.getters["member/isLogin"]
  ) {
    return true;
  }
  return false;
}

export function redirectAuthorization(to: RouteLocationNormalized): boolean {
  if (to.name !== "Authorization") {
    return (
      store.getters["member/isLogin"] &&
      !store.getters["member/isAuthorization"]
    );
  }
  return false;
}
