import { RouteRecordRaw } from "vue-router";

export default [
  {
    path: "/notice",
    name: "Notice",
    component: () => import("@/pages/Board/Notice.vue"),
  },
  {
    path: "/notice/:id",
    name: "NoticeDetail",
    component: () => import("@/pages/Board/NoticeDetail.vue"),
  },
  {
    path: "/report/upload",
    name: "ReportUpload",
    component: () => import("@/pages/Board/ReportUpload.vue"),
    meta: { loginRequired: true },
  },
  {
    path: "/want/upload",
    name: "WantUpload",
    component: () => import("@/pages/Board/WantUpload.vue"),
    meta: { loginRequired: true },
  },
] as Array<RouteRecordRaw>;
