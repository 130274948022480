import axios, { AxiosInstance } from "axios";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();
export default class AxiosService {
  static interceptor: number | null = null;
  static readonly instance: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 10000,
    headers: {
      post: {
        "Content-Type": "application/json",
        "X-YSIN-DEVICE-TOKEN": cookies.get("fcm"),
        "X-YSIN-DEVICE-TYPE": cookies.get("os"),
      },
      get: {
        "X-YSIN-DEVICE-TOKEN": cookies.get("fcm"),
        "X-YSIN-DEVICE-TYPE": cookies.get("os"),
      },
      delete: {
        "X-YSIN-DEVICE-TOKEN": cookies.get("fcm"),
        "X-YSIN-DEVICE-TYPE": cookies.get("os"),
      },
    },
  });
  static injectToken = (access: string | null): void => {
    if (AxiosService.interceptor != null) {
      AxiosService.instance.interceptors.request.eject(
        AxiosService.interceptor
      );
    }
    if (access != null) {
      AxiosService.interceptor = AxiosService.instance.interceptors.request.use(
        function (config) {
          config.headers.Authorization = `JWT ${access}`;
          return config;
        }
      );
    }
  };
}
