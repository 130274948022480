import { App } from "vue";
import AxiosService from "@/service/axios.service";
import { useCookies } from "vue3-cookies";

export default {
  install: (app: App): void => {
    const { cookies } = useCookies();
    const access = cookies.get("access");
    const refresh = cookies.get("refresh");

    if (access && refresh) {
      AxiosService.injectToken(access);
      app.config.globalProperties.$store.dispatch("member/updateToken", {
        access,
        refresh,
      });
    }
  },
};
