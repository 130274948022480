import { RouteRecordRaw } from "vue-router";

export default [
  {
    path: "/urgent",
    name: "Urgent",
    component: () => import("@/pages/Urgent/Root.vue"),
  },
  {
    path: "/urgent/:id",
    name: "UrgentDetail",
    component: () => import("@/pages/Urgent/Detail.vue"),
  },
  {
    path: "/urgent/:id/update",
    name: "UrgentUpdate",
    component: () => import("@/pages/Urgent/Update.vue"),
  },
  {
    path: "/urgent/search",
    name: "UrgentSearch",
    component: () => import("@/pages/Urgent/Search.vue"),
  },
  {
    path: "/urgent/upload",
    name: "UrgentUpload",
    component: () => import("@/pages/Urgent/Upload.vue"),
    meta: { loginRequired: true },
  },
] as Array<RouteRecordRaw>;
