import { RouteRecordRaw } from "vue-router";

export default [
  {
    path: "/login/before",
    name: "LoginBefore",
    component: () => import("@/pages/Member/LoginBefore.vue"),
  },
  {
    path: "/signup/before",
    name: "SignupBefore",
    component: () => import("@/pages/Member/SignupBefore.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/pages/Member/Login.vue"),
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import("@/pages/Member/Signup.vue"),
  },
  {
    path: "/password",
    name: "Password",
    component: () => import("@/pages/Member/Password.vue"),
  },
  {
    path: "/newpassword",
    name: "NewPassword",
    component: () => import("@/pages/Member/NewPassword.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/pages/Member/Profile/Root.vue"),
  },
  {
    path: "/profile/urgent",
    name: "ProfileUrgent",
    component: () => import("@/pages/Member/Profile/Urgent.vue"),
  },
  {
    path: "/profile/talkroom",
    name: "ProfileTalkroom",
    component: () => import("@/pages/Member/Profile/Talkroom.vue"),
  },
  {
    path: "/profile/comment",
    name: "ProfileComment",
    component: () => import("@/pages/Member/Profile/Comment.vue"),
  },
] as Array<RouteRecordRaw>;
