import { createStore } from "vuex";
import { mainStore, MainState } from "@/store/main";
import { memberStore, MemberState } from "@/store/member";
import { talkroomStore, TalkroomState } from "@/store/talkroom";
import { urgentStore, UrgentState } from "@/store/urgent";

export interface RootState {
  MainStore: MainState;
  MemberStore: MemberState;
  TalkroomStore: TalkroomState;
  UrgentStore: UrgentState;
}

export default createStore({
  modules: {
    member: memberStore,
    main: mainStore,
    talkroom: talkroomStore,
    urgent: urgentStore,
  },
});
