import { RouteRecordRaw } from "vue-router";

export default [
  {
    path: "/setting",
    name: "Setting",
    component: () => import("@/pages/Setting/Setting.vue"),
  },
  {
    path: "/setting/quit",
    name: "SettingQuit",
    component: () => import("@/pages/Setting/MemberQuit.vue"),
    meta: { loginRequired: true },
  },
  {
    path: "/setting/agreement",
    name: "SettingAgreement",
    component: () => import("@/pages/Setting/Agreement.vue"),
  },
  {
    path: "/setting/agreement/service",
    name: "SettingAgreementService",
    component: () => import("@/pages/Setting/Service.vue"),
  },
  {
    path: "/setting/agreement/privacy",
    name: "SettingAgreementPrivacy",
    component: () => import("@/pages/Setting/Privacy.vue"),
  },
] as Array<RouteRecordRaw>;
