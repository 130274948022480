import { RouteRecordRaw } from "vue-router";

export default [
  {
    path: "/alert",
    name: "Alert",
    component: () => import("@/pages/Alert/Root.vue"),
    meta: { loginRequired: true },
  },
  {
    path: "/alert/setting",
    name: "AlertSetting",
    component: () => import("@/pages/Alert/Setting.vue"),
    meta: { loginRequired: true },
  },
  {
    path: "/alert/keyword",
    name: "AlertKeyword",
    component: () => import("@/pages/Alert/Keyword.vue"),
    // meta: { loginRequired: true },
  },
] as Array<RouteRecordRaw>;
