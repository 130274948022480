import { App } from "vue";

export default {
  install: (app: App): void => {
    app.config.globalProperties.$timeAgo = (value: Date): string => {
      const today = new Date();
      const betweenTime = Math.floor(
        (today.getTime() - value.getTime()) / 1000 / 60
      );
      if (betweenTime < 1) return "방금 전";
      if (betweenTime < 60) {
        return `${betweenTime}분 전`;
      }

      const betweenTimeHour = Math.floor(betweenTime / 60);
      if (betweenTimeHour < 24) {
        return `${betweenTimeHour}시간 전`;
      }

      const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
      if (betweenTimeDay < 365) {
        return `${betweenTimeDay}일 전`;
      }

      return `${Math.floor(betweenTimeDay / 365)}년 전`;
    };
    app.config.globalProperties.$convertToDate = (value: string): Date => {
      if (value) {
        const spl: number[] = value.split(/[-: ]/).map((v) => Number(v));

        if (spl.length == 3) {
          return new Date(spl[0], spl[1] - 1, spl[2]);
        }

        if (spl.length == 6) {
          return new Date(spl[0], spl[1] - 1, spl[2], spl[3], spl[4], spl[5]);
        }
      }

      return new Date();
    };
    app.config.globalProperties.$pushGAEvent = (
      event: string,
      args?: any
    ): void => {
      const d = args || {};
      d.page_hash = window.location.hash.substring(1);
      d.timestamp = app.config.globalProperties.$timestamp();

      window.flutter_inappwebview.callHandler("pushGAEvent", {
        event,
        parameters: JSON.stringify(d),
      });
    };
    app.config.globalProperties.$isDarkMode = (): boolean => {
      return !!(
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      );
    };
    app.config.globalProperties.$timestamp = (): string => {
      const d = new Date();
      let s = d.getFullYear().toString();
      s += "-";
      const mm = d.getMonth() + 1;
      let mmm = mm.toString();
      if (mm < 10) mmm = "0" + mmm;
      s += mmm + "-";
      const dd = d.getDate();
      let ddd = dd.toString();
      if (dd < 10) ddd = "0" + ddd;
      s += ddd + " ";
      const h = d.getHours();
      let hh = h.toString();
      if (h < 10) hh = "0" + hh;
      s += hh + ":";
      const m = d.getMinutes();
      let nm = m.toString();
      if (m < 10) nm = "0" + nm;
      s += nm + ":";
      const ss = d.getSeconds();
      let sss = ss.toString();
      if (ss < 10) sss = "0" + sss;
      s += sss;
      return s;
    };
  },
};
