import store from "@/store";
import AxiosService from "@/service/axios.service";
import { useCookies } from "vue3-cookies";

export async function injectAuth(): Promise<void> {
  const { cookies } = useCookies();
  const access = cookies.get("access");
  const refresh = cookies.get("refresh");

  if (access && refresh) {
    AxiosService.injectToken(access);
    await store.dispatch("member/updateToken", {
      access,
      refresh,
    });

    cookies.remove("access");
    cookies.remove("refresh");
  }
}
