import type { ComponentPublicInstance, ComponentCustomProperties } from "vue";
import JwtDecode from "jwt-decode";

import { Module } from "vuex";
import { RootState } from "./index";

export interface TokenModel {
  access: string;
  refresh?: string;
}

export interface MemberState {
  isLogin: boolean;
  token: TokenModel;
  isShowEmailAlert: boolean;
  /* TODO: user decode */
  member: any;
}

export const memberStore: Module<MemberState, RootState> = {
  namespaced: true,
  state: () => ({
    isLogin: false,
    isShowEmailAlert: false,
    token: {
      access: "",
      refresh: "",
    },
    member: null,
  }),
  mutations: {
    setToken(state, token: TokenModel) {
      Object.assign(state.token, token);
    },
    setMember(state, member: any) {
      state.member = member;
    },
    setIsLogin(state, isLogin: boolean) {
      state.isLogin = isLogin;
    },
    setIsShowEmailAlert(state, isShowEmailAlert: boolean) {
      state.isShowEmailAlert = isShowEmailAlert;
    },
    clearMember(state) {
      state.member = null;
    },
    clearToken(state) {
      Object.assign(state.token, { access: "", refresh: "" });
    },
  },
  getters: {
    member(state): any {
      return state.member;
    },
    token(state): string {
      return state.token ? state.token.access : "";
    },
    isLogin(state) {
      return state.isLogin;
    },
    isAuthorization(state) {
      return state.member ? state.member.is_authorized : false;
    },
    isShowEmailAlert(state) {
      return state.isShowEmailAlert;
    },
  },
  actions: {
    updateToken({ commit }, token: TokenModel): void {
      try {
        const member = JwtDecode(token.access);
        commit("setToken", token);
        commit("setMember", member);
        commit("setIsLogin", true);
      } catch (e) {
        console.log(token, e);
      }
    },
    updateIsShowEmailAlert({ commit }, value: boolean) {
      commit("setIsShowEmailAlert", value);
    },
    signOut({ commit }): void {
      commit("clearToken");
      commit("clearMember");
      commit("setIsLogin", false);
      commit("setIsShowEmailAlert", false);
    },
  },
};
