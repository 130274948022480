import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import My from "./My";
import Alert from "./Alert";
import Board from "./Board";
import Urgent from "./Urgent";
import Member from "./Member";
import Setting from "./Setting";
import Talkroom from "./Talkroom";
import Announcement from "./Announcement";
import { injectAuth } from "@/middlewares/auth";
import {
  redirectLogin,
  redirectAuthorization,
} from "@/middlewares/login_guards";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/pages/Home.vue"),
  },
  {
    path: "/authorization",
    name: "Authorization",
    component: () => import("@/pages/Authorization.vue"),
    meta: { loginRequired: true },
  },
  {
    path: "/report",
    name: "Report",
    component: () => import("@/pages/Report.vue"),
    meta: { loginRequired: true },
  },
  ...My,
  ...Alert,
  ...Board,
  ...Urgent,
  ...Member,
  ...Setting,
  ...Talkroom,
  ...Announcement,
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const type = window?.event?.type;
  if (window && window.flutter_inappwebview) {
    if (type == "popstate") {
      window.flutter_inappwebview.callHandler("popHistory");
    } else if (
      !["/talkroom", "/urgent", "/announcement", "/", "/my"].includes(to.path)
    ) {
      window.flutter_inappwebview.callHandler("pushHistory", to.path);
    }
  }

  injectAuth();
  if (redirectLogin(to)) {
    next({ name: "LoginBefore", query: { redirect: to.fullPath } });
  } else if (redirectAuthorization(to)) {
    next({ name: "Authorization", query: { redirect: to.fullPath } });
  } else {
    next();
  }
});

export default router;
