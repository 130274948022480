import { RouteRecordRaw } from "vue-router";

export default [
  {
    path: "/my",
    name: "My",
    component: () => import("@/pages/My/Root.vue"),
  },
  {
    path: "/my/profile/modify",
    name: "ProfileModify",
    component: () => import("@/pages/My/ProfileModify.vue"),
    meta: { loginRequired: true },
  },
] as Array<RouteRecordRaw>;
