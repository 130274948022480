import { Module } from "vuex";
import { RootState } from "./index";

export interface MainState {
  os: string;
  isLoading: boolean;
  user: any;
}

export const mainStore: Module<MainState, RootState> = {
  namespaced: true,
  state: () => ({
    os: "",
    isLoading: false,
    user: {},
  }),
  mutations: {
    setOs(state, _value) {
      state.os = _value;
    },
    setIsLoading(state, _value) {
      state.isLoading = _value;
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    os(state) {
      return state.os;
    },
  },
  actions: {
    updateIsLoading({ commit }, val) {
      commit("setIsLoading", val);
    },
  },
};
