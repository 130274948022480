import { RouteRecordRaw } from "vue-router";

export default [
  {
    path: "/announcement",
    name: "Announcement",
    component: () => import("@/pages/Announcement/Root.vue"),
  },
  {
    path: "/announcement/search",
    name: "AnnouncementSearch",
    component: () => import("@/pages/Announcement/Search.vue"),
  },
] as Array<RouteRecordRaw>;
