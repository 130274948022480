import { Module } from "vuex";
import { RootState } from "./index";

export interface UrgentState {
  selectedTabName: string;
}

export const urgentStore: Module<UrgentState, RootState> = {
  namespaced: true,
  state: () => ({
    selectedTabName: "actor",
  }),
  mutations: {
    setSelectedTabName(state, _value) {
      state.selectedTabName = _value;
    },
  },
  getters: {
    selectedTabName(state) {
      return state.selectedTabName;
    },
  },
  actions: {
    updateSelectedTabName({ commit }, val) {
      commit("setSelectedTabName", val);
    },
  },
};
