
import { useStore } from "vuex";
import { defineComponent } from "vue";

export default defineComponent({
  name: "APP",
  data() {
    const store = useStore();
    return {
      store,
      redirectTo: "/",
    };
  },
  methods: {
    loginSnsCallback(access: string, refresh: string, method: string): void {
      this.$pushGAEvent("login", {
        method,
      });
      this.$injectToken(access);
      this.store.dispatch("member/updateToken", { access, refresh });
      if (this.redirectTo === "/") {
        this.$flutter.callHandler("selectGnb", {
          path: "/",
        });
      } else {
        this.$router.replace(this.redirectTo);
      }
    },
    setOs(os: string): void {
      this.store.commit("main/setOs", os);
    },
  },
  beforeMount() {
    window.loginSnsCallback = this.loginSnsCallback;
    window.setOs = this.setOs;
  },
  mounted() {
    const path = this.$route.query as { redirect: string } | null;
    if (path && path?.redirect) {
      this.redirectTo = path.redirect;
    }
  },
});
